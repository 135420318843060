.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #070F2B;
    padding: 15px 0;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin: 0 auto; 
    left: 0;
    right: 0;

}

.navbar ul {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.navbar ul li {
    margin: 0 15px;
}

.navbar ul li a {
    color: white;
    text-decoration: none;
    font-size: 1.2em;
    padding: 10px;
    cursor: pointer;
}

.navbar ul li a.active {
    color: #9290C3;
}


@media (max-width: 768px) {
    .navbar ul {
        flex-direction: column;
        align-items: center;
        background-color: #070F2B;
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        display: none;
    }

    .navbar ul.show {
        display: flex; 
    }

    .navbar ul li {
        margin: 10px 0;
    }

    .hamburger {
        display: block;
        position: absolute;
        right: 20px;
        top: 15px;
        cursor: pointer;
        z-index: 1001;
    }

    .hamburger div {
        width: 25px;
        height: 3px;
        background-color: white;
        margin: 5px;
    }
}
