
.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
}

.project-card {
    background-color: rgba(83, 92, 145, 0.2);
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    margin: 10px 0;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.project-card:hover {
    transform: translateY(-10px); 
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); 
}

.project-card h3 {
    color: #9290C3; 
    margin-bottom: 10px;
}

.project-card p, .project-card ul {
    font-size: 0.8em;
    color: white;
}

.project-card ul {
    padding-left: 20px; 
}

.project-card li {
    margin-bottom: 5px;
}


