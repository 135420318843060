
html, body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.App {
  background-color: #070F2B;
  color: white;
  min-height: 100vh;
}





