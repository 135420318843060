
.timeline, .education-timeline {
    position: relative;
    margin: 0 auto;
    padding: 40px 0;
    max-width: 800px;
    color: #333;
}


.timeline::before, .education-timeline::before {
    content: '';
    position: absolute;
    width: 2px;
    background-color: #9F87C1; 
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.education-timeline::before {
    background-color: #86d8f2;
}

.timeline-item, .education-timeline .timeline-item {
    padding: 20px 40px;
    position: relative;
    width: 50%;
    box-sizing: border-box;
    transition: all 0.3s ease;
}

.timeline-item:nth-child(odd)::before,
.timeline-item:nth-child(even)::before,
.education-timeline .timeline-item:nth-child(odd)::before,
.education-timeline .timeline-item:nth-child(even)::before {
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.timeline-item:nth-child(odd), .education-timeline .timeline-item:nth-child(odd) {
    left: 0;
    text-align: right;
}

.timeline-item:nth-child(odd)::before, .education-timeline .timeline-item:nth-child(odd)::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #9F87C1;
    top: 20px;
    left: 100%;
    transform: translateX(-50%);
    border-radius: 50%;
    z-index: 1;
}

.education-timeline .timeline-item:nth-child(odd)::before {
    background-color: #86d8f2;
}

.timeline-item:nth-child(even), .education-timeline .timeline-item:nth-child(even) {
    left: 50%;
    text-align: left;
}

.timeline-item:nth-child(even)::before, .education-timeline .timeline-item:nth-child(even)::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #9F87C1;
    top: 20px;
    left: 0;
    transform: translateX(-50%);
    border-radius: 50%;
    z-index: 1;
}

.education-timeline .timeline-item:nth-child(even)::before {
    background-color: #86d8f2;
}

.timeline-item:hover:nth-child(odd)::before,
.timeline-item:hover:nth-child(even)::before,
.education-timeline .timeline-item:hover:nth-child(odd)::before,
.education-timeline .timeline-item:hover:nth-child(even)::before {
    transform: translateX(-50%) scale(1.5);
    background-color: #9F87C1;
}

.education-timeline .timeline-item:hover:nth-child(odd)::before,
.education-timeline .timeline-item:hover:nth-child(even)::before {
    background-color: #86d8f2;
}

.timeline-item h3, .education-timeline .timeline-item h3 {
    margin: 0;
    font-size: 1.2em;
    color: #9290C3;
}

.timeline-item span, .education-timeline .timeline-item span {
    display: block;
    font-size: 0.9em;
    color: white;
    margin-bottom: 10px;
}

.timeline-item p, .education-timeline .timeline-item p {
    margin: 0;
    font-size: 0.8em;
    line-height: 1.5;
    color: white;
}

@media (max-width: 768px) {
    .timeline::before, .education-timeline::before {
        left: 26px; 
    }

    .timeline-item, .education-timeline .timeline-item {
        width: 100%;
        padding-left: 40px;
        padding-right: 20px;
        text-align: left;
    }

    .timeline-item:nth-child(odd), .timeline-item:nth-child(even),
    .education-timeline .timeline-item:nth-child(odd), .education-timeline .timeline-item:nth-child(even) {
        left: 0;
    }

    .timeline-item:nth-child(odd)::before, .timeline-item:nth-child(even)::before,
    .education-timeline .timeline-item:nth-child(odd)::before, .education-timeline .timeline-item:nth-child(even)::before {
        left: 20px; 
        top: 20px;
        transform: translateX(0); 
    }

    .timeline-item:hover:nth-child(odd)::before,
    .timeline-item:hover:nth-child(even)::before,
    .education-timeline .timeline-item:hover:nth-child(odd)::before,
    .education-timeline .timeline-item:hover:nth-child(even)::before {
        transform: scale(1.5) translateX(0);
    }
}

@media (max-width: 480px) {
    .timeline-item h3, .education-timeline .timeline-item h3 {
        font-size: 1em;
    }

    .timeline-item span, .education-timeline .timeline-item span {
        font-size: 0.8em;
    }

    .timeline-item p, .education-timeline .timeline-item p {
        font-size: 0.7em;
    }

    .timeline-item:nth-child(odd)::before, .timeline-item:nth-child(even)::before,
    .education-timeline .timeline-item:nth-child(odd)::before, .education-timeline .timeline-item:nth-child(even)::before {
        left: 20px; 
    }

    .timeline-item:hover:nth-child(odd)::before,
    .timeline-item:hover:nth-child(even)::before,
    .education-timeline .timeline-item:hover:nth-child(odd)::before,
    .education-timeline .timeline-item:hover:nth-child(even)::before {
        transform: scale(1.5) translateX(0);
    }
}




